import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { getCourseNavigation } from "../../../../../store/courses"
import LayoutCourse from "../../../../../layouts/course"
import Box from "../../../../../components/box"
import Paragraph from "../../../../../components/paragraph"
import CourseFooterNext from "../../../../../components/course-footer-next"
import Heading from "../../../../../components/heading"
import Seo from "../../../../../components/seo"
import Stack from "../../../../../components/stack"
import Tooltip from "../../../../../components/tooltip"
import Grid from "../../../../../components/grid"
import Image from "../../../../../components/image"

const Page = () => {
  const navigation = getCourseNavigation({ courseId: "haelfte-des-lebens" })
  const data = useStaticQuery(graphql`
    query KurseZahlenspieleQuery {
      nachtgesaenge01: file(
        relativePath: { eq: "kurse/nachtgesaenge-01.png" }
      ) {
        ...largeImage
      }
      nachtgesaenge02: file(
        relativePath: { eq: "kurse/nachtgesaenge-02.png" }
      ) {
        ...largeImage
      }
      nachtgesaenge03: file(
        relativePath: { eq: "kurse/nachtgesaenge-03.png" }
      ) {
        ...largeImage
      }
      nachtgesaenge04: file(
        relativePath: { eq: "kurse/nachtgesaenge-04.png" }
      ) {
        ...largeImage
      }
    }
  `)

  return (
    <LayoutCourse
      navigation={navigation}
      footer={
        <CourseFooterNext to="/kurse/haelfte-des-lebens/02-entstehungsgeschichte/zahlenspiele/bloedigkeit" />
      }
    >
      <Seo title="Versteckten Zahlenspielen nachgehen" />
      <Box>
        <Stack>
          <Stack space={3}>
            <Heading as="h1" level={2}>
              Versteckten Zahlenspielen nachgehen
            </Heading>
          </Stack>
          <Paragraph>
            Dass Hölderlin die sieben Gedichte als ›Nachtgesänge‹ bezeichnete
            und eigens für den Druck in Wilmans ›Taschenbuch‹ zusammengestellt
            hat, legt nahe, dass sie in einer besonderen Verbindung zueinander
            stehen und einen geschlossenen Zyklus bilden. Ebenso lässt die
            Tatsache, dass sie in Wilmans Sammlung eine eigene Rubrik erhielten
            und in nummerierter Abfolge gedruckt wurden, vermuten, dass
            Hölderlin die Aufeinanderfolge der Gedichte genau durchdacht und
            kalkuliert hat. Sehen wir uns die Nummerierungen also einmal genauer
            an...
          </Paragraph>
          <Paragraph>
            Die ersten vier Gedichte sind ebenso wie das sechste Gedicht im
            Versmaß der{" "}
            <Tooltip content="Besteht aus vier Versen, wobei die ersten beiden Verse 11 Silben, der dritte Vers 9 Silben und der vierte Vers 10 Silben umfasst.">
              Alkäischen Ode
            </Tooltip>{" "}
            verfasst.
          </Paragraph>
          <Grid columns={[2, 4]} space={1} alignY="center">
            <Image
              alt=""
              image={data.nachtgesaenge01.childImageSharp.gatsbyImageData}
            />
            <Image
              alt=""
              image={data.nachtgesaenge02.childImageSharp.gatsbyImageData}
            />
            <Image
              alt=""
              image={data.nachtgesaenge03.childImageSharp.gatsbyImageData}
            />
            <Image
              alt=""
              image={data.nachtgesaenge04.childImageSharp.gatsbyImageData}
            />
          </Grid>
          <Paragraph>
            An zweiter Stelle steht das Gedicht ›Thränen‹, das aus der
            Weiterentwicklung des Entwurfs ›Sapphos Schwanengesang‹
            hervorgegangen ist.
          </Paragraph>
        </Stack>
      </Box>
    </LayoutCourse>
  )
}

export default Page
